import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component'
import { LoginComponent } from './login/login.component'




const routes: Routes = [
	{ path: 'login', component: LoginComponent},
	{ path: 'gatepass', loadChildren: () => import('./gatepass/gatepass.module').then(m => m.GatepassModule) },
	{ path: '', component: LoginComponent, pathMatch: 'full' },
	{ path: '**', component: PagenotfoundComponent}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }

