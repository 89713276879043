import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { loginService } from './services/login.service'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { LoginModule } from './login/login.module'; 
import { GatepassModule } from './gatepass/gatepass.module';



@NgModule({
  declarations: [
    AppComponent,
    PagenotfoundComponent,
    
  ],
  imports: [
      BrowserModule,
	  AppRoutingModule,
	  LoginModule,
	  GatepassModule,
	  BrowserAnimationsModule,
	
  ],
	providers: [loginService],
  bootstrap: [AppComponent]
})
export class AppModule { }
