import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class gatePassService {


	baseUrl: string = "";
	constructor(private httpClient: HttpClient, location: Location) { }

	public getMaterials() {
		this.baseUrl = location.protocol + '//' + location.hostname + location.pathname;
		return this.httpClient.get(this.baseUrl + "gatePassMethods.aspx/getMaterials", { headers: { 'Content-Type': 'application/json; charset=utf-8' } });
	}

	
}


