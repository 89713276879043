import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { gatepassRoutingModule } from './gatepass-routing.module'
import { CreategatepassComponent } from './creategatepass/creategatepass.component';
import { GatepasslistComponent } from './gatepasslist/gatepasslist.component';
import { gatePassService } from '../services/gatePass.service'


@NgModule({
  declarations: [GatepasslistComponent, CreategatepassComponent],
  imports: [
	  CommonModule,
	  FormsModule,
	  HttpClientModule,
	  gatepassRoutingModule
	  
	],
	providers: [gatePassService]
})
export class GatepassModule { }
