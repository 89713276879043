import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { CreategatepassComponent } from './creategatepass/creategatepass.component';
import { GatepasslistComponent } from './gatepasslist/gatepasslist.component';
import { PagenotfoundComponent } from '../pagenotfound/pagenotfound.component'


const routes: Routes = [
	{ path: 'gatepasslist', component: GatepasslistComponent },
	{ path: 'creategatepass', component: CreategatepassComponent },
	
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class gatepassRoutingModule { }