import { Component, OnInit } from '@angular/core';
import { gatePassService } from '../../services/gatePass.service';

export interface material {
	name: string;
	qty: number;
	amt: number;
	desc: string;
}

@Component({
  selector: 'app-creategatepass',
  templateUrl: './creategatepass.component.html'
})
export class CreategatepassComponent implements OnInit {

    matList;
	retArray = [];
	searchName = "";
	matLoadingDesc = "";
	gatepassItems: material[] = [];
	isSubmitted = false;
    
	constructor(private gatePassService: gatePassService) {
		
	}

	ngOnInit() {

		this.matLoadingDesc = "loading materials...";
		this.gatePassService.getMaterials().subscribe((data) => {
			this.matList = JSON.parse(data['d'])['materials'];
			this.matLoadingDesc = "Search gatepass item...";
		});
    }

	addItem() {
		if (this.searchName) {
			this.gatepassItems.push({ name: this.searchName, qty: 1, amt: 0, desc: "" });
			this.searchName = "";
		}
		else {
			alert("Invalid Item Name");
		}
	}
	deleteItem(index) {
		this.gatepassItems.splice(index, 1);
	}
	clearItem() {
		this.searchName = "";
	}

	selectItem() {
		this.retArray = [];
		return this.retArray;
	}

	onSubmit() {
		this.isSubmitted = true;
	}

	finalList(): any[] {
		this.retArray = [];
		if (!this.matList || !this.searchName || this.searchName.length <=2) { this.retArray = []; }
		else {
			this.retArray = this.matList.filter(it =>
				it['Materials'].toString().toLowerCase().indexOf(this.searchName.toString().toLowerCase()) == 0);
		}
		return this.retArray;
	}
}
