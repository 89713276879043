import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
 providedIn: 'root'
})
export class loginService {

	private loginSource = new BehaviorSubject(false);
	currentMessage = this.loginSource.asObservable();

	constructor() { }

	loginAuth(source: boolean) {
	    this.loginSource.next(source);
	}

}