import { HostListener, Component, OnInit } from '@angular/core';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent{

	title = 'Angular-app';
	loginComp: boolean = false;
	baseUrl: string;

	constructor() { }

}


