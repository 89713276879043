import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {loginService  } from '../services/login.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
	
	authLog: string;

	constructor(private loginService: loginService) { }

	ngOnInit() {
		this.loginService.loginAuth(true);
    }

}
